import React from 'react'
import Component from './index.js'
import { evolve } from 'ramda'

const transformItem = item => ({
  ...item,
  id: item._uid,
  items: item.items?.map(transformItem),
})

export default function exitableTransformer(props) {
  return <Component {...evolve({ data: transformItem }, props)} />
}
